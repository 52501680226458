/*!
* Copyright © Spartan Controls
* All Rights Reserved.
*/

:root {
	/* Colors */
	--primary: #1a2954;
	--secondary: #f6f1f2;
	--accent: #de8e2b;
    --auxiliary: #735132;

	--text: #222222;

	--grey: #4c4c4c;
	--light-grey: #c6c6c6;

    /* Fonts */
	--base-font: 'Source Sans 3', sans-serif;
	--base-font-strong : 'Source Sans 3', sans-serif;
	--base-font-w : 300;
	--base-font-strong-w: 700;

	--accent-font: 'Noto Sans', sans-serif;
	--accent-font-strong : 'Noto Sans', sans-serif;
	--accent-font-w: 400;
	--accent-font-strong-w: 500;

	--base-font-size: 20px;

	/* Animation Speed */
	--default-transition: all 300ms ease-in-out;
}

.default-transition {
    transition: var(--default-transition);
}

