/*!
* Copyright © Spartan Controls
* All Rights Reserved.
*/

@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  --primary: #3E69B8;
}

body {
  margin: 0;
  font-family: "Roboto", sans-serif;
  font-optical-sizing: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: black;
}

b,
strong {
  font-family:var(--base-font-strong, 'Verdana');
  font-weight:var(--base-font-strong-w, bold);
}

h1, h2, h3, h4, h5, h6 {
  margin-top: .5rem;
  margin-bottom: 1rem;
  font-family: var(--accent-font, 'Garamond');
  font-weight: var(--accent-font-w, normal);
  line-height: 1.2;
  color: inherit;
}

h1 {
  font-size: 2.0rem;
}

h2 {
  font-size: 1.75rem;
}

h3 {
  font-size: 1.50rem;
}

h4 {
  font-size: 1.4rem;
}

h5 {
  font-size:1.3rem;
}

h6 {
  font-size:1.3rem;
}

p {
  font-size:1.3rem;
  margin-bottom:1rem;
}

.productviewer-content p {
  margin-bottom:2.5rem;
}

.productviewer-content h1 {
  font-size:1.3rem;
}

ul, ol {
  font-size:1.3rem;
  margin-left:1.5rem;
  margin-bottom:2.5rem;
}

ul ul,
ol ol {
  margin-top: 0.5rem;
  margin-bottom: 0;
}

ul li,
ol li {
  margin-bottom:0.5rem;
}

ul {
  list-style-type: disc;
}

ol {
  list-style-type: decimal;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

#root {
  position: relative;
  background: black;
  height: 100%;
  min-width: 1000px;
}

main {
  position: relative;
  min-width: 1280px;
  min-height: 720px;
  margin: 0 auto;
}

.button {
  border:solid thin #fff;
  color:white;
 
  border-radius: 35px;
  cursor: pointer;
  transition: background-color 0.5s;
}

/*
.button:hover {
  background-color: var(--primary, white);
}
*/

.button.blue {
  border-color:var(--primary, white);
  color:var(--primary, white);
}

.button.blue:hover {
  background-color: var(--primary, white);
  color:white;
  border-color:white;
}

.coordinates {
	background:#fff;
	color:#333;
	padding:7px;
	position:absolute;
	z-index: 100000000;
	font-size: 12px;
  bottom:0;
  left:0;
}

.marker {
  transform: translateX(0%) translateY(-50%);
}

.button-circle {
  transform: translateX(-50%) translateY(-50%);
}

.marker.bottom {
  transform: translateX(-50%) translateY(-100%);
}

.marker-line {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.prod-skid {
  top:27%;
  left:15%;
}

.grid-pattern {
  background-color: rgba(255,255,255,0.8);
  background-image: linear-gradient(45deg, rgba(0,0,0,0.1) 25%, transparent 25%, transparent 50%, rgba(0,0,0,0.1) 50%, rgba(0,0,0,0.1) 75%, transparent 75%, transparent);
  background-size: 28.28px 28.28px; /* This value is the side length * sqrt(2) to account for the 45 degree angle */
  width: 100%;
  height: 100%;
}

.scrollbar-thin {
  scrollbar-width: thin; /* For Firefox */
  scrollbar-color: var(--primary) transparent; /* For Firefox */
}

.scrollbar-thin::-webkit-scrollbar {
  width: 8px; /* For Chrome, Safari, and Opera */
}

.scrollbar-thin::-webkit-scrollbar-track {
  background: transparent;
  padding: 10px 0;
}

.scrollbar-thin::-webkit-scrollbar-thumb {
  background: var(--primary);
  border-radius: 4px;
}

.product-image {
  background-size: auto 60%;
}

.product-thumb {
  /*background-size: auto 60%;*/
}

.noselect {
  -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
     -khtml-user-select: none; /* Konqueror HTML */
       -moz-user-select: none; /* Old versions of Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
            user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}

.product-thumb.active {
  border-top:solid 3px var(--primary);
}

.start-label {
  transform: rotate(-56deg);
}

.special-height {
  height: 12vw;
  max-height: 220px;
  min-height: 153px;
}

.ReactModalPortal {
  z-index: 1000;
  position: relative;
}